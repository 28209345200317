import { RouteKey } from "hooks/useRoutes";

export const GET_OFFER_INITIAL_ROUTE_KEY: RouteKey = "specialOffer";

const ROUTES_LT = {
  home: "/",
  aboutUs: "/apie-mus",
  mobileApp: "/programele",
  contactUs: "/kontaktai",
  faq: "/duk",
  privacyPolicy: "/privatumo-politika",
  warehouse: "/erdves",
  storage: "/erdves/:storageId",
  reserve: "/rezervuoti/:storageId",
  reserveSuccess: "/rezervuoti/sekme",
  reserveFailure: "/rezervuoti/klaida",
  specialOffer: "/specialus-pasiulymas",
  warehouseSelector: "/#rezervuoti",
  landingContact: "/#susisiekti",
  mapWebview: "/map-webview",
  vehicleRentalRules: "/transporto-nuomos-taisykles",
  mobileAppRentalTerms: "/mobilaus-naudojimo-nuomos-salygos",
  equipmentRentalRules: "/irangos-nuomos-taisykles",
  incidentResponseMemo: "/eismo-ivykiu-atmintine",
  termsAndConditions: "/naudojimosi-salygos",
  storageRentAgreementTerms: "/nuomos-sutarties-bendrosios-salygos",
  deleteYourAccount: "/paskyros-istrynimas",
};

const ROUTES_EN = {
  home: "/",
  aboutUs: "/about",
  mobileApp: "/mobile-app",
  contactUs: "/contact-us",
  faq: "/faq",
  privacyPolicy: "/privacy-policy",
  warehouse: "/spaces",
  storage: "/spaces/:storageId",
  reserve: "/reserve/:storageId",
  reserveSuccess: "/reserve/success",
  reserveFailure: "/reserve/failure",
  specialOffer: "/special-offer",
  warehouseSelector: "/#reserve",
  landingContact: "/#contact",
  mapWebview: "/map-webview",
  vehicleRentalRules: "/vehicle-rental-rules",
  mobileAppRentalTerms: "/mobile-app-rental-terms",
  equipmentRentalRules: "/equipment-rental-rules",
  incidentResponseMemo: "/incident-response-memo",
  termsAndConditions: "/terms-and-conditions",
  storageRentAgreementTerms: "/storage-rent-agreement-terms",
  deleteYourAccount: "/delete-your-account",
};

const ROUTES_RU = {
  home: "/",
  aboutUs: "/o-nas",
  mobileApp: "/prilozenie",
  contactUs: "/kontakty",
  faq: "/czv",
  privacyPolicy: "/konfidencialnost-politika",
  warehouse: "/prostranstva",
  storage: "/prostranstva/:storageId",
  reserve: "/rezervirovat/:storageId",
  reserveSuccess: "/rezervirovat/uspeh",
  reserveFailure: "/rezervirovat/sboj",
  specialOffer: "/specpredlozenie",
  warehouseSelector: "/#rezervirovat",
  landingContact: "/#kontakt",
  mapWebview: "/map-webview",
  vehicleRentalRules: "/pravila-arendy-transporta",
  mobileAppRentalTerms: "/usloviya-arenda-cherez-app",
  equipmentRentalRules: "/pravila-arendy-oborudovaniya",
  incidentResponseMemo: "/pamyatka-dtp-i-proisshestviya",
  termsAndConditions: "/uslovija",
  storageRentAgreementTerms: "/usloviya-dogovora-arenda",
  deleteYourAccount: "/udalit-vash-akkaunt",
};

export const ROUTES_LOCALES = { lt: ROUTES_LT, en: ROUTES_EN, ru: ROUTES_RU };
