import i18n from "i18next";
import { initReactI18next } from "react-i18next";
import backend from "i18next-http-backend";
import LanguageDetector from "i18next-browser-languagedetector";

i18n
  .use(backend)
  .use(initReactI18next)
  .use(LanguageDetector)
  .init({
    fallbackLng: "lt",
    supportedLngs: ["lt", "en", "ru"],
    detection: {
      caches: ["localStorage"],
      order: ["localStorage"],
    },
    backend: {
      loadPath: "/locales/{{lng}}/{{ns}}.json",
      requestOptions: {
        cache: "no-store",
      },
    },
  });

i18n.on("languageChanged", (lng: "en" | "ru" | "lt") => {
  if (lng === "ru") {
    //loading font which supports russian language
    const link = document.createElement("link");
    link.rel = "stylesheet";
    link.href = "https://fonts.googleapis.com/css2?family=Exo+2:wght@400;500;700&display=swap";
    document.head.appendChild(link);
  }
});

export default i18n;
