import classNames from "classnames";
import { ROUTES_LOCALES } from "constants/routes";
import { RouteKey } from "hooks/useRoutes";
import { FunctionComponent, LazyExoticComponent, Suspense, lazy } from "react";
import { useTranslation } from "react-i18next";
import { BrowserRouter, Route, Routes } from "react-router-dom";
import { QueryParamProvider } from "use-query-params";
import { ReactRouter6Adapter } from "use-query-params/adapters/react-router-6";
import queryString from "query-string";
import { UrlLocalizer } from "components/url-localizer";

const PrivacyPolicyPage = lazy(() => import("./privacy-policy"));
const TermsAndConditionsPage = lazy(() => import("./terms-and-conditions"));
const AboutUsPage = lazy(() => import("./about-us"));
const ContactUsPage = lazy(() => import("./contact-us"));
const FaqPage = lazy(() => import("./faq"));
const MobileAppPage = lazy(() => import("./mobile-app"));
const HomePage = lazy(() => import("./home"));
const WarehousePage = lazy(() => import("./warehouse"));
const StoragePage = lazy(() => import("./storage"));
const ReservePage = lazy(() => import("./reserve"));
const ReserveSuccessPage = lazy(() => import("./reserve/success"));
const ReserveFailurePage = lazy(() => import("./reserve/failure"));
const SpecialOfferPage = lazy(() => import("./special-offer"));
const MapWebviewPage = lazy(() => import("./map-webview"));
const NotFoundPage = lazy(() => import("./not-found"));
const VehicleRentalRulesPage = lazy(() => import("./vehicle-rental-rules"));
const MobileAppRentalTermsPage = lazy(() => import("./mobile-app-rental-terms"));
const EquipmentRentalRulesPage = lazy(() => import("./equipment-rental-rules"));
const IncidentResponseMemoPage = lazy(() => import("./incident-response-memo"));
const StorageRentAgreementTermsPage = lazy(() => import("./storage-rent-agreement-terms"));
const DeleteYourAccountPage = lazy(() => import("./delete-your-account"));

const routes: { routeKey: RouteKey; element: LazyExoticComponent<FunctionComponent> }[] = [
  { routeKey: "home", element: HomePage },
  { routeKey: "aboutUs", element: AboutUsPage },
  { routeKey: "mobileApp", element: MobileAppPage },
  { routeKey: "contactUs", element: ContactUsPage },
  { routeKey: "faq", element: FaqPage },
  { routeKey: "privacyPolicy", element: PrivacyPolicyPage },
  { routeKey: "termsAndConditions", element: TermsAndConditionsPage },
  { routeKey: "warehouse", element: WarehousePage },
  { routeKey: "storage", element: StoragePage },
  { routeKey: "reserve", element: ReservePage },
  { routeKey: "reserveSuccess", element: ReserveSuccessPage },
  { routeKey: "reserveFailure", element: ReserveFailurePage },
  { routeKey: "specialOffer", element: SpecialOfferPage },
  { routeKey: "mapWebview", element: MapWebviewPage },
  { routeKey: "vehicleRentalRules", element: VehicleRentalRulesPage },
  { routeKey: "mobileAppRentalTerms", element: MobileAppRentalTermsPage },
  { routeKey: "equipmentRentalRules", element: EquipmentRentalRulesPage },
  { routeKey: "incidentResponseMemo", element: IncidentResponseMemoPage },
  { routeKey: "storageRentAgreementTerms", element: StorageRentAgreementTermsPage },
  { routeKey: "deleteYourAccount", element: DeleteYourAccountPage },
];

interface PagesProps {}

const Pages: FunctionComponent<PagesProps> = () => {
  const { i18n } = useTranslation();
  const languages = ["lt", "en", "ru"] as const;

  return (
    <div
      className={classNames("font-medium text-sm leading-normal font-general-sans", {
        "font-ru": i18n.language === "ru",
        "font-general-sans": i18n.language !== "ru",
      })}
    >
      <BrowserRouter>
        <QueryParamProvider
          adapter={ReactRouter6Adapter}
          options={{
            searchStringToObject: (val) => queryString.parse(val, { arrayFormat: "separator", arrayFormatSeparator: "-" }),
            objectToSearchString: (val) => queryString.stringify(val, { arrayFormat: "separator", arrayFormatSeparator: "-" }),
          }}
        >
          <Suspense>
            <Routes>
              {languages.map((lng) =>
                routes.map((route) => (
                  <Route
                    key={`route-${route.routeKey}-${lng}`}
                    element={<route.element />}
                    path={ROUTES_LOCALES[lng][route.routeKey]}
                  />
                ))
              )}
              <Route element={<NotFoundPage />} path="*" />
            </Routes>
          </Suspense>
          <UrlLocalizer />
        </QueryParamProvider>
      </BrowserRouter>
    </div>
  );
};

export default Pages;
