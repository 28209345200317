import { SupportedLanguage } from "constants/languages";
import { ROUTES_LOCALES } from "constants/routes";
import { RouteKey } from "hooks/useRoutes";

export function getCurrentRouteData(pathname: string): [RouteKey | undefined, SupportedLanguage | undefined] {
  for (const [lang, routes] of Object.entries(ROUTES_LOCALES)) {
    for (const [key, path] of Object.entries(routes)) {
      const regexPath = path.replace(/:\w+/g, "\\w+");
      const regex = new RegExp(`^${regexPath}$`);
      if (regex.test(pathname)) {
        return [key as RouteKey, lang as SupportedLanguage];
      }
    }
  }

  return [undefined, undefined];
}
